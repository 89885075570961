/**
 * accessing local storage
 */
// eslint-disable-next-line import/prefer-default-export
// import Cookie from 'universal-cookie';
import { faArchive, faFile, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileExcel, FilePdf, FilePpt, FileText, FileWord } from "../assets/Icons";
import { CheckboxItems, MenuList } from "../interface/common.interface";

export const getFileIcon = (type: string) => {
  const fileType: { [key: string]: JSX.Element } = {
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": <FilePpt />,
    "application/vnd.ms-powerpoint": <FilePpt />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FileExcel />,
    "application/vnd.ms-excel": <FileExcel />,
    "application/msword": <FileWord />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FileWord />,
    "text/plain": <FileText />,
    "application/pdf": <FilePdf />,
    "application/zip": <FontAwesomeIcon icon={faFileArchive} size="lg" />,
    "application/vnd.rar": <FontAwesomeIcon icon={faFileArchive} size="lg" />,
    default: <FontAwesomeIcon icon={faFile} size="lg" />,
  };
  return fileType[type] || fileType["default"];
};
export const rejectMessage = (code: string, _message: string) => {
  const message: { [key: string]: string } = {
    "too-many-files": "Maximum 5 files can be attached",
    "file-invalid-type": "This file is not supported",
    maxSize: _message,
  };
  return message[code];
};

export const FileTypes = [
  ".jpg",
  ".jpeg",
  ".png",
  ".svg",
  ".webp",
  ".bmp",
  ".mp4",
  ".mov",
  ".avi",
  ".webm",
  ".pdf",
  ".ppt",
  ".pptx",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".odp",
  ".odg",
  ".odt",
  "text/*",
  ".zip",
  ".rar",
];
export const getPriorityTextCss = (priority: string) => {
  const priorityText: { [key: string]: string } = {
    high: "text-error",
    critical: "text-error",
    medium: "text-warning",
    low: "text-secondary",
    default: "text-secondary",
  };
  return priorityText[priority] || priorityText.default;
};

export const sortMenuList: MenuList[] = [
  {
    menu: "By Id",
    sort: "id",
    item: [
      {
        value: "Ascending",
        id: "1",
      },
      {
        value: "Descending",
        id: "-1",
      },
    ],
  },
  {
    menu: "By Date",
    sort: "created_at",
    item: [
      {
        value: "Latest",
        id: "-1",
      },
      {
        value: "Oldest",
        id: "1",
      },
    ],
  },
];

// export const storageEngine = {
//   set: (
//     key: string,
//     value: string,
//     maxAge: number,
//     httpOnly?: boolean,
//   ) => new Cookie().set(key, value, { maxAge, httpOnly }),
//   get: (key: any) => new Cookie().get(key),
//   unset: (key: any) => new Cookie().set(key, ''),
// };

/* eslint-disable import/prefer-default-export */
export const randomBGColor = (firstLetter: string) => {
  const bgColor = [
    "bg-red-300",
    "bg-orange-300",
    "bg-yellow-300",
    "bg-green-300",
    "bg-teal-300",
    "bg-blue-300",
    "bg-indigo-300",
    "bg-purple-300",
    "bg-pink-300",
  ];
  const randomIndex = Math.floor((firstLetter?.toLowerCase().charCodeAt(0) - 97) % 8);
  const item = bgColor[randomIndex];
  return item;
};

export const randomTextColor = () => {
  const bgColor = [
    "text-red-900",
    "text-orange-900",
    "text-yellow-900",
    "text-green-900",
    "text-teal-900",
    "text-blue-900",
    "text-indigo-900",
    "text-purple-900",
    "text-pink-900",
  ];
  const randomIndex = Math.floor(Math.random() * bgColor.length);
  const item = bgColor[randomIndex];
  return item;
};

export const searchPlaceholderText: { [page: string]: string } = {
  "My Tickets": "Search By Title",
  "Assigned Tickets": "Search By Title",
  "All Tickets": "Search By Title",
  Users: "Search By Name or Email",
  Departments: "Search By Name",
  "Departments-View": "Search By Name",
  Tickets: "Search By Title",
};

export const priorityCSSClasses: { [priority: string]: string } = {
  low: "bg-green-100 border-green-700 text-green-700",
  medium: "bg-blue-100 border-blue-700 text-blue-700",
  high: "bg-orange-100 border-orange-700 text-orange-700",
  critical: "bg-red-100 border-red-700 text-red-700",
};

export const priorityCSSHoverClasses: { [priority: string]: string } = {
  low: "hover:bg-green-100 hover:border-green-700",
  medium: "hover:bg-blue-100 hover:border-blue-700",
  high: "hover:bg-orange-100 hover:border-orange-700",
  critical: "hover:bg-red-100 hover:border-red-700",
};

export const statusList: CheckboxItems[] = [
  { value: "Open", id: "pending" },
  // { value: 'For Review', id: 'forReview' },
  // { value: 'Submitted', id: 'submitted' },
  { value: "Rejected", id: "rejected" },
  // { value: 'In Progress', id: 'inProgress' },
  { value: "Closed", id: "completed" },
  // { value: 'On Hold', id: 'onHold' },
  // { value: 'Reopened', id: 'reopened' },
  // { value: 'In Transfer', id: 'inTransfer' },
  // { value: 'Cancelled', id: 'cancelled' },
];

export const priorityList: CheckboxItems[] = [
  { value: "Low", id: "low" },
  { value: "Medium", id: "medium" },
  { value: "High", id: "high" },
  { value: "Critical", id: "critical" },
];

export const actionsList: CheckboxItems[] = [
  { value: "Nudged", id: "nudged" },
  { value: "Escalated", id: "escalated" },
];

// Converts a iso string to mm-dd-yyyy format
export const isoToDate = (isoString: string) => {
  const date = new Date(isoString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  // eslint-disable-next-line prefer-template
  return day + "-" + month + "-" + year;
};

// Get name initial from full name
export const getInitial = (name: string) => name?.split(" ").map((n) => n[0]);

// Returns a flat array from a tree
export const treetoList = (tree: any) =>
  flatten(extractChildren(tree), extractChildren).map(
    // eslint-disable-next-line no-param-reassign
    (x: any) => delete x.children && x
  );

export const getInitialLetters = (name: string) => {
  // var str = "Java Script Object Notation";
  var matches: any = name?.match(/\b(\w)/g);
  return matches?.join("").substring(0, 2);
};

export const byteToSize = (bytes: any) => {
  let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  let i = parseInt(Math.floor(Math.log(parseInt(bytes)) / Math.log(1024)).toString());
  return Math.round(bytes / Math.pow(1024, i)) + " " + sizes[i];
};

const flatten = (children: any, extractChildren: any) =>
  Array.prototype.concat.apply(
    children,
    children.map((x: any) => flatten(extractChildren(x) || [], extractChildren))
  );

const extractChildren = (x: any) => x.children;

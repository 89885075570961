import { takeEvery, put } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Api from '../../services/Apis';
import profileSlice from '../slices/profile.slice';
import { toast } from 'react-toastify';

function* fetchUserProfileData(action: PayloadAction<{ userId: number }>) {
  const { userId } = action.payload;
  try {
    const response: AxiosResponse = yield Api.profile.profileFetch({
      userId,
    });
    // console.log(response);
    yield put(profileSlice.actions.setProfileData(response.data.data));
  } catch (error) {
    console.log(error);
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message
    });
  }
}

function* updatePassword(
  action: PayloadAction<{
    id: string;
    currentPassword: string;
    password: string;
  }>,
) {
  //   const { id, password } = action.payload;
  try {
    const response: AxiosResponse = yield Api.profile.changePassword({
      id: action.payload.id + '',
      currentPassword: action.payload.currentPassword,
      password: action.payload.password,
    });
    toast.success('Password Updated Successfully!');
    yield put(profileSlice.actions.showHidePasswordModal(false));
    console.log(response);
  } catch (error: any) {
    yield put(profileSlice.actions.showHidePasswordModal(true));
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message
    });
    console.log(error);
  }
}

export default function* profileSaga() {
  yield takeEvery(
    profileSlice.actions.getProfileData.type,
    fetchUserProfileData,
  );
  yield takeEvery(profileSlice.actions.updateUserPassword.type, updatePassword);
}

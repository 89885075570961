import { APP_ENTRYPOINT } from '../../../config/config';
import { ResponseGenerator } from '../../../interface/Response';
import { request } from '../../request/Request';

function* logIn(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/login`,
    params,
  );
  return response;
}

function* logOut() {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/logout`,
  );
  return response;
}

function* signUp(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/signup`,
    params,
  );
  return response;
}

function* checkauth() {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}v1/auth/checkauth`,
  );
  return response;
}

function* setpassword({ password }: { password: string }) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/setpassword`,
    { password },
  );
  return response;
}

function* forgotPassword({ email }: { email: string }) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/forgotpassword`,
    { email },
  );
  return response;
}

function* resetPassword({
  email,
  password,
  code,
  hash
}: {
  password: string;
  email: string;
  code: string;
  hash: string;
}) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/auth/resetpassword`,
    {
      password,
      email,
      code,
      hash,
    },
  );
  return response;
}

const auth = {
  logIn,
  logOut,
  signUp,
  checkauth,
  setpassword,
  forgotPassword,
  resetPassword,
};

export default auth;

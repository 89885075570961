/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import withoutAuth from "./withoutAuth/withoutAuth";
import owner from "./owner/owner";
import builderModel from "./model/model";
import user from "./users/users";
import ticket from "./tickets/tickets";
import auth from "./auth/auth";
import group from "./groups/groups";
import profile from "./profile/profile";
import s3bucket from "./s3bucket/s3bucket";
const Api = {
  owner,
  withoutAuth,
  builderModel,
  user,
  ticket,
  auth,
  group,
  profile,
  s3bucket,
};

export default Api;

import React from "react";

const AngleArrowRight: React.FC<{ disable?: boolean }> = ({ disable = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" fill="none" viewBox="0 0 6 10">
      <path
        stroke={disable ? "#A7BACD" : "#121920"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1 9l4-4-4-4"></path>
    </svg>
  );
};

export default AngleArrowRight;

import { AxiosRequestConfig } from "axios";
import { APP_ENTRYPOINT } from "../../../config/config";
import { ResponseGenerator } from "../../../interface/Response";
import { request } from "../../request/Request";
// keys =[]
const getPresignedUrls = async (keys: string[]) => {
  const response: ResponseGenerator = await request.get(
    `${APP_ENTRYPOINT}v1/uploads/getPresignedUrls`,
    { params: { keys } },
  );
  return response;
};
// keys =[]
const deleteObjects = async (keys: string[]) => {
  const response: ResponseGenerator = await request.post(
    `${APP_ENTRYPOINT}v1/uploads/deleteObjects`,
    { keys },
  );
  return response;
};

const uploadFile = async (url: string, file: any, config?: AxiosRequestConfig) => {
  const headers = { "Content-Type": file.type };
  const response: ResponseGenerator = await request.put(url, file, { ...config, headers });
  return response;
};

const s3bucket = { getPresignedUrls, deleteObjects, uploadFile };

export default s3bucket;

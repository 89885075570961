import React from "react";
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import { PopupClose } from "../../assets/images";
import Modal from "../Modal/Modal";

const contentStyle = {
  maxWidth: "400px",
  width: "100%",
};

export const Confirmation = (props: {
  message?: string;
  options?: { value: boolean; label: string }[];
  open: boolean;
  close?: any;
  title?: string;
  showClose?: boolean;
}) => {
  const { message, options, open, close, title, showClose } = props;

  const cancelClass = "text-black hover:bg-black bg-white px-4 hover:text-white";
  const confirmClass = "bg-black hover:bg-white text-white hover:text-black px-10";

  return (
    <Modal
      open={open}
      close={close}
      // closeOnDocumentClick={false}
      // onClose={close}
      // contentStyle={contentStyle}
    >
      <Modal.Title className="text-primary text-xl">{title}</Modal.Title>
      <Modal.Body className="mx-8 my-4">
        <div className="py-6">
          <label>{message}</label>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-end xs:pr-1 sm:px-8">
        {options?.map((button) => (
          <button
            type="button"
            className={`rounded-3xl xs:mr-2 xs:ml-1 sm:mr-0 sm:ml-4 outline-none h-42 min-w-136 font-bold border border-black ${
              button.value ? confirmClass : cancelClass
            }`}
            onClick={() => {
              close(button.value);
            }}
            key={button.label}>
            {button.label}
          </button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default Confirmation;

Confirmation.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.bool,
      label: PropTypes.string,
    })
  ),
  showClose: PropTypes.bool,
};

Confirmation.defaultProps = {
  open: false,
  close: () => {},
  title: "Confirmation",
  message: "Do you want to delete this item?",
  options: [
    {
      value: false,
      label: "Cancel",
    },
    {
      value: true,
      label: "Confirm",
    },
  ],
  showClose: true,
};

import React, { useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";
import Confirmation from "../../components/Confirmation/Confirmation";

function ErrorFallback({ error }: FallbackProps) {
  // Handle failed lazy loading of a JS/CSS chunk.
  const [chunkError, setChunkError] = useState(false);
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    setChunkError(!!(error?.message && chunkFailedMessage.test(error.message)));
  }, [error]);

  const clearVersionAndReload = () => {
    window.localStorage.removeItem('x-app-version');
    window.location.reload();
  };

  return (
    <Confirmation
      open
      title={chunkError ? 'Upgrade Available' : 'Something went wrong'}
      message={
        chunkError
          ? 'Please click \'Upgrade Now\' button to get the latest version of the app.'
          : 'Please reload the page to see if the issue is solved. If not, Please report to the Application Suppoort Department'
      }
      options={[{ value: true, label: chunkError ? 'Upgrade Now' : 'Reload this page' }]}
      close={() => clearVersionAndReload()}
      showClose={false}
    />
  );
}

export default ErrorFallback;

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const createUser = (state: any, action: any) => state;

const getUsersList = (state: any, action: any) => {};

const setUsersList = (state: { list: any }, action: { payload: any }) => {
  state.list = action.payload;
};

const updateUser = (state: any, action: any) => {
};

const deleteUser = (state: any, action: any) => {
};

const getSearchUserList = (state: any, action: any) => {};

const setSearchUserList = (state: { searchUserList: any }, action: { payload: any }) => {
  state.searchUserList = action.payload;
};

const bulkCreateUser = (state: any, action: any) => state;

const bulkCreateUserResult = (state: any, action: any) => ({ ...state, bulkCreateUserRes: action.payload });

const recoverUserInit = (state: any, action: any) => ({ ...state, userToRecover: action.payload });

const recoverUser = (state: any, action: any) => state;

export interface InitialUserState {
  list: any[];
  user: any[];
  searchUserList: any[];
  bulkCreateUserRes?: any;
  userToRecover?: any;
}

export const initialUserState: InitialUserState = {
  list: [],
  user: [],
  searchUserList: [],
  bulkCreateUserRes: null,
  userToRecover: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    createUser,
    getUsersList,
    setUsersList,
    updateUser,
    deleteUser,
    getSearchUserList,
    setSearchUserList,
    bulkCreateUser,
    bulkCreateUserResult,
    recoverUserInit,
    recoverUser,
  },
});

export default userSlice;

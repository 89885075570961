/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { ResponseGenerator } from '../../../interface/Response';
import { request } from '../../request/Request';
// import axios from 'axios';

// function* createOwnerService(value: any): any {
//   return yield request.post(
//     `${APP_ENTRYPOINT}/builder/owner-create`,
//     value,
//   );
// }

function* getGroupsListService(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}v1/group/`,
    { params }
  );
  return response;
}

function* getGroupMembersService(params: any, id: number) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}v1/group/${id}/members`,
    { params }
  );
  return response;
}
function* deleteGroupService(params: any) {
  const response: ResponseGenerator = yield request.delete(
    `${APP_ENTRYPOINT}v1/group/${params.id}`
  );
  return response;
}

function* createGroupService(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/group/create`,
    params
  );
  return response;
}

function* updateGroupService( params: any ) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}v1/group/update`,
    params,
    
  );
  return response;
}

function* deleteGroupMemberService(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/group/remove-user`,
    params
  );
  return response;
}

function* addGroupMembersService( params: any ) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/group/add-user`,
    params,
    
  );
  return response;
}

function* setDefaultGroupMemberService( params: any ) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/group/set-default-user`,
    params,
  );
  return response;
}

function* recoverGroup(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/group/recoverGroup`,
    params,
  );
  return response;
}

const groups = {
  updateGroupService,
  createGroupService,
  getGroupsListService,
  getGroupMembersService,
  deleteGroupService,
  deleteGroupMemberService,
  addGroupMembersService,
  setDefaultGroupMemberService,
  recoverGroup,
};

export default groups;

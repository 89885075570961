/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {searchPlaceholderText} from '../../common/helper';

type InitialState = {
  searchText?: string;
  placeholderText?: string;
  currentPage?: string;
  isSideBar: boolean;
  animationCount: number
  
};

export const initialCommonState: InitialState = {
  searchText: undefined,
  placeholderText: undefined,
  currentPage: undefined,
  isSideBar: false,
  animationCount:0,
  
};

export const setShowSideNav = (state: InitialState, action: PayloadAction<boolean>) => {
  const open = action.payload;
  state.animationCount = open ? 1 : 2;
  state.isSideBar = open;
}
export const setAnimationCount = (state: InitialState, action: PayloadAction<number>) => {
  const count = action.payload;
  state.animationCount = count;
}
export const setSearchText = (
  state: InitialState,
  action: PayloadAction<string | undefined>
) => {
  state.searchText = action.payload;
};

export const setCurrentPage = (
  state: InitialState,
  action: PayloadAction<string>
) => {
  // state.searchText = undefined;
  state.currentPage = action.payload;
  state.placeholderText = searchPlaceholderText[action.payload]
};

const commonSlice = createSlice({
  name: 'common',
  initialState: initialCommonState,
  reducers: {
    setSearchText,
    setCurrentPage,
    setShowSideNav,
    setAnimationCount
  },
});
export default commonSlice;

/* eslint-disable import/prefer-default-export */
export const EN = {
  PASSWORD_REQUIRED: "Password confirm is required",
};

export const PAGE_CONFIG = {
  LIMIT: 10,
  DEFAULT_PAGE_NO: 1,
};

export const SHEDULE_DURATION = [
  {
    duration: 1,
    label: "1M",
  },
  {
    duration: 2,
    label: "2M",
  },
  {
    duration: 3,
    label: "3M",
  },
  {
    duration: 6,
    label: "6M",
  },
  {
    duration: 12,
    label: "1Y",
  },
];

export const ReactSelectStyle = {
  theme: (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "rgb(40, 40, 40)",
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 28,
      baseUnit: 2.5,
    },
  }),
  styles: {
    input: (base: any) => ({
      ...base,
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: 16,
      color: "black",
      fontWeight: "500",
      paddingBottom: 2,
    }),
    option: (styles: any, { isSelected }: { isSelected: boolean }) => ({
      ...styles,
      fontWeight: "400",
      fontSize: 16,
      paddingTop: 16,
      paddingBottom: 16,
      paddingLeft: 24,
      borderRadius: 24,
      lineHeight: 1,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: isSelected ? "#EBF0F4" : "#121920",
    }),
    valueContainer: (base: any) => ({
      ...base,
      paddingLeft: 24,
      fontSize: 13,
      paddingTop: 13,
      paddingBottom: 13,
    }),
    container: (base: any) => ({
      ...base,
      paddingBottom: 2,
    }),
    control: (base: any) => ({
      ...base,
      // borderColor: "#A0AEC0",
      borderColor: "currentcolor",
      borderRadius: 24,
      fontWeight: "500",
      boxShadow: 0,
    }),
    menu: (base: any) => ({
      ...base,
      borderRadius: 24,
      padding: "1px 4px",
    }),
  },
  components: {
    IndicatorSeparator: () => null,
  },
};

export const DATE_FORMAT = "YYYY-MM-DD";

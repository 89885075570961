import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Data } from '../../interface/profile.interface';

export type InitialProfileDataType = {
  userProfile: Data | null;
  userId?: number;
  isPasswordChangeOpen: boolean;
  isPasswordChanging: boolean;
};

export const initialUserProfileState: InitialProfileDataType = {
  userProfile: null,
  userId: 0,
  isPasswordChangeOpen: false,
  isPasswordChanging: false,
};

export const getProfileData = (
  state: InitialProfileDataType,
  action: PayloadAction<{ userId: number }>,
) => {
  state.userId = action.payload.userId;
};

export const setProfileData = (
  state: InitialProfileDataType,
  action: PayloadAction<Data>,
) => {
  state.userProfile = action.payload;
};

export const updateUserPassword = (
  state: InitialProfileDataType,
  action: PayloadAction<{
    id: string;
    currentPassword: string;
    password: string;
  }>,
) => {
  state.isPasswordChanging = true;
};

export const showHidePasswordModal = (
  state: InitialProfileDataType,
  action: PayloadAction<boolean>,
) => {
  state.isPasswordChanging = false;
  state.isPasswordChangeOpen = action.payload;
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialUserProfileState,
  reducers: {
    getProfileData,
    setProfileData,
    updateUserPassword,
    showHidePasswordModal,
  },
});

export default profileSlice;

export const CREATE_TICKET = "ticket/createTicket";
export const GET_TICKETS_FOR_USER = "ticket/getTicketsForUser";
export const GET_TICKETS_FOR_GROUP = "ticket/getTicketsForGroup";
export const GET_TICKET_BY_ID = "ticket/getTicketById";
export const DELETE_TICKET = "ticket/deleteTicket";
export const CHANGE_TICKET_STATUS = "ticket/updateTicketStatus";
export const UPDATE_TICKET = "ticket/updateTicket";
export const GET_COMMENTS = "ticket/getCommentsForTicket";
export const UPDATE_COMMENT = "ticket/updateComment";
export const CREATE_COMMENT = "ticket/createComment";
export const DELETE_COMMENT = "ticket/deleteComment";
export const SEARCH_GROUPS = "ticket/searchGroups";
export const SET_GROUP_SEARCH = "ticket/setGroupSearch";
export const UPDATE_TICKET_STATUS = "ticket/updateTicketStatus";
export const GET_ASSIGNED_TICKETS = "ticket/getAssignedTickets";
export const GET_GROUP_ASSIGNED_TICKETS = "ticket/getGroupAssignedTickets";
export const REASSIGN_TICKETS = "ticket/reassignTickets";
export const REASSIGN_TICKETS_GROUP = "ticket/reassignTicketsGroup";
export const EXPORT_DATA = "ticket/exportData";
export const REMOVE_ATTACHMENT = "ticket/removeAttachment";

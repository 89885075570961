import React from "react";

const AngleArrowDown: React.FC<{ rotate?: boolean; disable?: boolean }> = ({
  rotate = false,
  disable = false,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" viewBox="0 0 10 6">
      <path
        stroke={disable ? "#A7BACD" : "#2F4964"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d={rotate ? "M 1 4 l 4 -3 l 4 3" : "M1 1l4 4 4-4"}></path>
    </svg>
  );
};

export default AngleArrowDown;

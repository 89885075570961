/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const createGroup = (state: any, action: any) => state;

const getGroupsList = (state: any, action: any) => {};

const setGroupsList = (state: { list: any }, action: { payload: any }) => {
  state.list = action.payload;
};

const getGroupMembers = (state: any, action: any) => {};

const setGroupMembers = (state: { members: any }, action: { payload: any }) => {
  state.members = action.payload;
};

const updateGroup = (state: any, action: any) => {
};

const deleteGroup = (state: any, action: any) => {
};

const deleteGroupMember = (state: any, action: any) => {
};

const addGroupMembers = (state: any, action: any) => {
};

const setDefaultGroupMember = (state: any, action: any) => {
};

const recoverGroupInit = (state: any, action: any) => ({ ...state, groupToRecover: action.payload });

const recoverGroup = (state: any, action: any) => state;

export interface InitialGroupState {
  list: any[];
  members: any[];
  groupToRecover?: any;
}

export const initialGroupState: InitialGroupState = {
  list: [],
  members: [],
  groupToRecover: null,
};

const groupSlice = createSlice({
  name: 'group',
  initialState: initialGroupState,
  reducers: {
    createGroup,
    getGroupsList,
    setGroupsList,
    getGroupMembers,
    setGroupMembers,
    updateGroup,
    deleteGroup,
    deleteGroupMember,
    addGroupMembers,
    setDefaultGroupMember,
    recoverGroupInit,
    recoverGroup,
  },
});

export default groupSlice;

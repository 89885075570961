// @ts-check
import React from "react";
import Popup from "reactjs-popup";

const ModalTitle: React.FC<{ className?: string }> = ({ className, ...restProps }) => {
  return (
    <div className="border border-b border-t-0 border-l-0 border-r-0 h-76 border-matecream min-h-16  flex justify-start items-center">
      <label className={`px-8 ${className}`}>{restProps.children}</label>
    </div>
  );
};

const ModalBody: React.FC<{ className?: string }> = ({ className, ...restProps }) => {
  return <div className={`${className}`}>{restProps.children}</div>;
};

const ModalFooter: React.FC<{
  className?: string;
  enableBackgroud?: boolean;
}> = ({ className, enableBackgroud = true, ...restProps }) => {
  return (
    <div
      className={`rounded-b-2xl border-b-0 border-l-0 border-r-0 border-t border-matecream h-91 items-center flex ${
        enableBackgroud && "bg-modal-footer"
      }  ${className}`}>
      {restProps.children}
    </div>
  );
};

const Modal: React.FC<{
  close?: any;
  open: boolean;
  showClose?: boolean;
}> = ({ open, close, showClose = true, ...restProps }) => {
  return (
    <Popup open={open} modal closeOnDocumentClick={false}>
      <div className="bg-activeBlack bg-opacity-50 absolute inset-0 flex justify-center items-center z-50 flex-col font-Quicksand">
        <div>
          {showClose && (
            <div className="flex justify-end items-center -mr-4 -mb-5">
              <button
                className="bg-white rounded-full h-35 w-35 flex justify-center items-center border-2 border-matecream cursor-pointer z-40 "
                onClick={() => close(!open)}>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.00023 5.58599L11.9502 0.635986L13.3642 2.04999L8.41423 6.99999L13.3642 11.95L11.9502 13.364L7.00023 8.41399L2.05023 13.364L0.63623 11.95L5.58623 6.99999L0.63623 2.04999L2.05023 0.635986L7.00023 5.58599Z"
                    fill="#2F4964"
                  />
                </svg>
              </button>
            </div>
          )}
          <div className=" grid bg-white rounded-2xl h-auto xs:w-310 sm:w-auto sm:min-w-460">
            {restProps.children}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default Object.assign(Modal, {
  Title: ModalTitle,
  Body: ModalBody,
  Footer: ModalFooter,
});

import { APP_ENTRYPOINT } from '../../../config/config';
import { AxiosResponse } from 'axios';
import { request } from '../../request/Request';

function* profileFetch(params: { userId: number }) {
  const response: AxiosResponse = yield request.get(
    `${APP_ENTRYPOINT}v1/users/getUserProfileDetails?userId=${params.userId}`,
  );
  return response;
}

function* changePassword(params: {
  id: string;
  currentPassword: string;
  password: string;
}) {
  const response: AxiosResponse = yield request.put(
    `${APP_ENTRYPOINT}v1/users/changePassword`,
    params,
  );
  console.log(response);
  return response;
}

const profile = {
  profileFetch,
  changePassword,
};

export default profile;

const Close: React.FC<{ size?: number; stroke?: string; opactity?: number }> = ({
  size = 14,
  stroke = "#2F4964",
  opactity = "0.32",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      fill="none"
      viewBox="0 0 12 12">
      <g stroke={stroke} strokeLinecap="round" strokeLinejoin="round" opacity={`${opactity}`}>
        <path d="M9 3L3 9M3 3l6 6"></path>
      </g>
    </svg>
  );
};

export default Close;

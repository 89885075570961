const FileUpload: React.FC<{
  size?: number;
  rotate?: boolean;
  fill?: string;
  opacity?: string;
}> = ({ size = 30, rotate = false, fill = "#fff", opacity = "1" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      fill="none"
      viewBox="0 0 20 20"
      rotate="45">
      {rotate ? (
        <path
          d="m 2.5 15.833 h 15 v 1.667 h -15 v -1.667 z m 8.333 -7.5 v -6.333 h -1.666 v 6.333 h -5.834 l 6.667 6.667 l 6 -6.7 h -4 z"
          fill={fill}
          opacity={opacity}
        />
      ) : (
        <path
          fill={fill}
          opacity={opacity}
          d="M2.5 15.833h15V17.5h-15v-1.667zm8.333-7.5V15H9.167V8.333H3.333L10 1.667l6.667 6.666h-5.834z"
        />
      )}
    </svg>
  );
};

export default FileUpload;

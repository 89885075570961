import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import { navigator } from '../../navigation/navigator';
// import { storageEngine } from '../../common/helper';
import authSlice, { UserState } from '../../redux/slices/auth.slice';
import store from '../../redux/store/store';
// import { Auth } from 'aws-amplify';

const request = Axios.create();
request.interceptors.request.use((config) => ({
  ...config, withCredentials: true,
}));
// const user = await Auth.currentSession();
// const token = user.getIdToken().getJwtToken();
// const token = storageEngine.get('Authorization');
// if (token) {
//   // eslint-disable-next-line no-param-reassign
//   config.headers = {
//     ...config.headers,
//     Cookie: `Authorization=${token ?? ''}; HttpOnly`,
//   };
//   // eslint-disable-next-line no-param-reassign
// }
request.interceptors.response.use(
  (response) => {
    const newAppVersion = response.headers['x-app-version'];
    if (newAppVersion) {
      const currentAppVersion = window.localStorage.getItem('x-app-version');
      window.localStorage.setItem('x-app-version', newAppVersion);
      if (currentAppVersion && currentAppVersion !== newAppVersion) {
        navigator.history.replace('/update-available');
        return response;
      }
    }
    const token = document.cookie?.split('Authorization=')?.[1]?.split(';')?.[0];
    if (token && token?.trim()) {
      try {
        const user: UserState = jwtDecode(token);
        if (response?.data?.last_login_on) {
          user.last_login_on = response?.data?.last_login_on;
        }
        store.dispatch(authSlice.actions.setAuthenticatedUser(user));
      } catch (err: any) {
        store.dispatch(authSlice.actions.clearAuthentication());
      }
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 401 && !error.request?.responseURL?.match(/(?:login)/g)) {
      // const dispatch = useDispatch();
      store.dispatch(authSlice.actions.clearAuthentication());
    }
    // throw error;
    throw error;
  },
);

// eslint-disable-next-line import/prefer-default-export
export { request };

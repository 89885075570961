/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, call, put } from 'redux-saga/effects';

import Api from '../../services/Apis/index';
import authSlice from '../slices/auth.slice';
import loaderSlice from '../slices/loader.slice';
// import { storageEngine } from '../../common/helper';
import { navigator } from '../../navigation/navigator';
import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  CHECK_AUTH,
  CHECK_STATUS,
  VERIFY_EMAIL,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  RESEND_VERIFY_MAIL,
  SET_PASSWORD,
} from '../actions/auth.actions';
// import { Logout } from '../../assets/images';
// import auth from '../../services/Apis/auth/auth';
import { SET_AUTHENTICATED_USER } from '../actions/user.actions';
import { ResponseGenerator } from '../../interface/Response';

function* handleUnverified(email: string) {
  try {
    toast.success('Please Verify your Email');
    navigator.history.replace('/verifyEmail', { email });
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.response?.data?.message ?? err.message);
  }
}

function* login(action: PayloadAction<{ username: string; password: string }>) {
  const { password, username } = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.auth.logIn({
      username,
      password,
    });
    // if (response.status !== 401) {
    //   yield put(
    //     authSlice.actions.setAuthenticatedUser({
    //       ...response?.data?.user,
    //     }),
    //   );
    // }
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    if (err.code === 'UserNotConfirmedException') {
      yield handleUnverified(username);
    } else {
      // yield put(authSlice.actions.setAuthentication(false));
      yield put(loaderSlice.actions.hide());
      toast.error('Invalid username or password');
    }
  }
}

function* logout() {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.auth.logOut();
    yield put(authSlice.actions.clearAuthentication());
    // yield storageEngine.unset('Authorization');
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(authSlice.actions.clearAuthentication());
    yield put(loaderSlice.actions.hide());
  }
}

function* signupBuilder(action: any) {
  try {
    const {
      email,
      companyName,
      companyLogo,
      firstName,
      lastName,
      address,
      city,
      state,
      zipCode,
      phoneNumber,
      password,
      userName,
    } = action.payload;
    yield put(loaderSlice.actions.show());
    const passAttributes = {
      username: email,
      password,
      attributes: {
        email,
        'custom:role': 'BUILDER',
      },
    };
    navigator.history.replace('/verifyEmail', { email });
    yield put(loaderSlice.actions.hide());
    toast.success('Verification code sent to your mail.');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message);
    yield put(loaderSlice.actions.hide());
  }
}

function* checkauth(
  action: PayloadAction<{
    newpassword: string;
    currentpassword: string;
    username: string;
  }>,
) {
  const pathName = navigator?.history?.location?.pathname;
  try {
    // yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.auth.checkauth();
    // if (response.status !== 401 && response.data.user) {
    //   yield put(
    //     authSlice.actions.setAuthenticatedUser({
    //       ...response.data.user,
    //     }),
    //   );
    // }
    // yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    // yield put(loaderSlice.actions.hide());
  }
}

function* checkstatus() {
  try {
    yield put(loaderSlice.actions.show());
    yield put(
      authSlice.actions.setAuthenticatedUser({
        user: {
          id: 'us-east-1:5ef102e3-e761-4aa3-bd63-a93b0e26d9c8',
          username: 'kiran.r@neoito.com',
          attributes: {
            sub: 'c7e0782d-12bf-4bfb-970c-b8dbf844e993',
            email_verified: true,
            'custom:role': 'BUILDER',
            email: 'kiran.r@neoito.com',
          },
        },
        cookies: '',
      }),
    );
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    if (err === 'No current user') {
      navigator.history.replace('/login');
    }
    yield put(loaderSlice.actions.hide());
  }
}

function* forgotpassword(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const { email } = action.payload;
    const response: ResponseGenerator = yield Api.auth.forgotPassword({
      email,
    });
    navigator.history.replace('/resetpassword', {
      email,
      hash: response.data.data,
    });
    toast.info('Verification code sent to your mail.');
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message);
    yield put(loaderSlice.actions.hide());
  }
}

function* resetpassword(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const { email, code, password, hash } = action.payload;
    yield Api.auth.resetPassword({
      email,
      code,
      password,
      hash,
    });
    navigator.history.replace('/resetPasswordSuccess');
    toast.success('Password changed successfully. Please Login');
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.response?.data?.message ?? err.message);
  }
}

function* setpassword(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const { password } = action.payload;
    const response: ResponseGenerator = yield Api.auth.setpassword({
      password,
    });
    if (response.status !== 401 && response.data.user) {
      // yield put(
      //   authSlice.actions.setAuthenticatedUser({
      //     ...response.data.user,
      //   }),
      // );
      // navigator.history.replace('/dashboard');
      // } else {
      // navigator.history.replace('/login');
    }
    toast.success('Password updated successfully');
    yield put(loaderSlice.actions.hide());
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.response?.data.message ?? err.message);
  }
}

function* changePassword(action: any) {
  try {
    const { currentPassword, newPassword } = action.payload;
    yield put(loaderSlice.actions.show());
    yield put(loaderSlice.actions.hide());
    toast.success('Successfully Changed Password');
  } catch (err: any) {
    if (err.code === 'NotAuthorizedException') {
      toast.error('Please enter correct current password');
    } else {
      toast.error(err.response?.data?.message ?? err.message);
    }
    yield put(loaderSlice.actions.hide());
  }
}

function* verifyEmail(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const { email, code } = action.payload;
    yield put(loaderSlice.actions.hide());
    toast.success('Successfully Verified email');
    navigator.history.replace('/verifyEmailSuccess');
  } catch (err: any) {
    if (err === 'not authenticated') {
      toast.success('Successfully Verified email. Please Login');
      navigator.history.replace('/login');
    } else {
      // yield put(authSlice.actions.verifyEmailError(err));
      toast.error(err.response?.data?.message ?? err.message);
    }
    yield put(loaderSlice.actions.hide());
  }
}

function* resendVerifyMail(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const { email } = action.payload;
    yield put(loaderSlice.actions.hide());
    toast.success('Verify Mail send successfully. Please check your email');
  } catch (err: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(err.response?.data?.message ?? err.message);
  }
}

// function* setAuthenticatedUser(action: any) {
//   try {
//     const { cookies } = action.payload;
//     const [key, value, maxAge, httpOnly] = [
//       cookies.split(';')?.[0]?.split('=')[0],
//       cookies.split(';')?.[0]?.split('=')[1],
//       +cookies.split(';')?.find((cook: any) => cook.includes('Max-Age'))?.split('=')[1],
//       !!cookies.split(';')?.find((cook: any) => cook.includes('HttpOnly')),
//     ];
//     yield storageEngine.set(key, value, maxAge, httpOnly);
//   } catch (error: any) {
//     toast.error(error.message);
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN, login);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(SIGNUP, signupBuilder);
  yield takeEvery(CHECK_AUTH, checkauth);
  yield takeEvery(FORGOT_PASSWORD, forgotpassword);
  yield takeEvery(RESET_PASSWORD, resetpassword);
  yield takeEvery(SET_PASSWORD, setpassword);
  yield takeEvery(CHECK_STATUS, checkstatus);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
  yield takeEvery(RESEND_VERIFY_MAIL, resendVerifyMail);
  yield takeEvery(VERIFY_EMAIL, verifyEmail);
  // yield takeEvery(SET_AUTHENTICATED_USER, setAuthenticatedUser);
}

export default authSaga;

import React from "react";

const ArrowUpAndDown: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16">
      <path
        fill="#2F4964"
        d="M9 4.667H5.667v10H4v-10H.667L4.833.5 9 4.667zm8.333 6.666L13.167 15.5 9 11.333h3.333v-10H14v10h3.333z"></path>
    </svg>
  );
};

export default ArrowUpAndDown;

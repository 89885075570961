const FileText: React.FC<{ size?: number }> = ({ size = 24 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill="#2A2C51"
        fillRule="evenodd"
        d="M5.221 2.072c-.18.082-.394.31-.438.465-.039.14-.039 18.786 0 18.926.044.155.258.383.438.465.15.068.458.071 6.764.072 5.886 0 6.623-.006 6.752-.06a.941.941 0 00.432-.389c.06-.107.065-.698.075-7.414.006-4.014-.004-7.354-.022-7.42-.035-.138-3.857-4.546-4.034-4.654-.094-.058-.548-.064-4.957-.063-4.61 0-4.861.004-5.01.072zm9.387 2.778v2.07l.114.128.114.127 1.818.011h1.35v4.142L18 15H6V3.28h8.608v1.57zM8.24 6.887c-.191.071-.215.366-.037.462.052.028.802.041 2.3.041 2.188 0 2.223 0 2.324-.08.127-.1.137-.268.021-.373-.079-.07-.18-.074-2.293-.081-1.383-.005-2.25.007-2.315.031zm-.108 2.169a.408.408 0 00-.08.14c0 .053.083.202.132.235.024.016 1.696.029 3.716.03 3.508 0 3.675-.003 3.737-.072a.269.269 0 00-.014-.358c-.077-.077-.13-.078-3.744-.078H8.213l-.08.103zm.011 2.067c-.077.077-.093.123-.074.205a.318.318 0 00.09.154c.05.039.858.05 3.738.05 3.51 0 3.678-.004 3.74-.072a.27.27 0 00-.014-.359c-.077-.077-.13-.078-3.729-.078H8.243l-.1.1zm.08 2.066a.246.246 0 00-.017.45c.05.027 1.294.04 3.67.04 3.617 0 3.709-.002 3.796-.132.055-.083.028-.247-.053-.32-.08-.072-.203-.075-3.678-.083-2.967-.007-3.618 0-3.718.045zm1.429 3.556v.449l-.654.01-.654.011-.01 1.924-.01 1.924H7.27v-3.867H5.983v-.899h3.669v.448zm1.749-.184c.443.96.618 1.32.649 1.33.019.008.092-.128.161-.301.07-.173.227-.535.35-.804l.224-.489h.604c.511 0 .6.009.582.057a43.95 43.95 0 01-.61 1.074c-.324.56-.608 1.06-.632 1.114-.04.087.019.204.645 1.285.378.654.688 1.2.688 1.213 0 .013-.272.023-.605.023h-.605l-.434-.869c-.35-.699-.442-.854-.467-.791-.06.147-.497 1.133-.618 1.396l-.12.264h-.605c-.333 0-.605-.009-.605-.02 0-.011.305-.555.679-1.208l.678-1.188-.524-.93c-.289-.512-.586-1.041-.661-1.176l-.137-.244h1.241l.122.264zm6.603.186v.449h-1.287l-.01 1.924-.01 1.924-.537.01-.537.011V17.196h-1.288v-.899h3.669v.45z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default FileText;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import Api from '../../services/Apis/index';
import { navigator } from '../../navigation/navigator';
import {
  CREATE_GROUP,
  DELETE_GROUP,
  GET_GROUPS_LIST,
  GET_GROUP_MEMBERS,
  UPDATE_GROUP,
  DELETE_GROUP_MEMBER,
  ADD_GROUP_MEMBERS,
  SET_DEFAULT_GROUP_MEMBER,
  RECOVER_GROUP,
} from '../actions/group.action';
import { ResponseGenerator } from '../../interface/Response';
import groupSlice from '../slices/group.slice';
import { PAGE_CONFIG } from '../../common/utility';

function* createGroup(action: any) {
  try {
    const { name, description, users, departmentRep } = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.createGroupService({
      name,
      description,
      users,
      departmentRep,
    });
    // navigator.history.replace('/dashboard/groups/');
    yield put(loaderSlice.actions.hide());
    if (response?.data?.recover) {
      yield put(groupSlice.actions.recoverGroupInit(response.data.group));
      return;
    }
    yield put(
      groupSlice.actions.getGroupsList({
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      }),
    );
    toast.success('Department created successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* updateGroup(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    // console.log(action);
    yield Api.group.updateGroupService(action.payload.values);
    // navigator.history.replace('/dashboard/groups');
    yield put(
      groupSlice.actions.getGroupsList({
        page: action.payload.filterData.page,
        limit: action.payload.filterData.limit,
      }),
    );
    yield put(loaderSlice.actions.hide());
    toast.success('Department updated successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* getGroupsList(action: any) {
  try {
    const params = action.payload;
    if (params.forTicket !== 'yes') {
      yield put(loaderSlice.actions.showSmall());
    }
    const response: ResponseGenerator = yield Api.group.getGroupsListService(
      params,
    );
    yield put(groupSlice.actions.setGroupsList(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (err: any) {
    console.log('group list');

    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hideSmall());
  }
}

function* getGroupMembers(action: any) {
  try {
    const { id, page, limit, text } = action.payload;
    const params = { page, limit, text };
    if (action.payload?.forTicket !== 'yes') {
      yield put(loaderSlice.actions.showSmall());
    }
    const response: ResponseGenerator = yield Api.group.getGroupMembersService(
      params,
      id,
    );
    yield put(groupSlice.actions.setGroupMembers(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (err: any) {
    console.log(err);
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hideSmall());
  }
}

function* deleteGroup(action: any) {
  try {
    console.log(action.payload);
    let params = { id: action.payload.selectedDeleteGroup };
    const { filterData, setFilterData, page, docsCount, searchText } =
      action.payload;
    let req = { ...filterData };
    docsCount === 1 && Number(page) > 1
      ? (req = {
          ...filterData,
          page: Number(page) - 1,
        })
      : (req = { ...filterData });
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.deleteGroupService(
      params,
    );
    yield setFilterData({
      text: searchText,
      limit: req.limit,
      page: req.page,
    });
    yield put(loaderSlice.actions.hide());
    toast.success('Department deleted successfully');
  } catch (err: any) {
    console.log(err);
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* deleteGroupMember(action: any) {
  try {
    let params = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator =
      yield Api.group.deleteGroupMemberService({
        id: params.id,
        users: params.users,
      });
    params.setFilterData({
      text: '',
      limit: params.filterData.limit,
      page: params.filterData.page,
      id: params.id,
    });
    yield put(loaderSlice.actions.hide());
    toast.success('Member removed successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* addGroupMembers(action: any) {
  try {
    let params = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.group.addGroupMembersService(
      params,
    );
    yield put(
      groupSlice.actions.getGroupMembers({
        id: action.payload.id,
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      }),
    );
    yield put(loaderSlice.actions.hide());
    toast.success('Member added successfully');
  } catch (err: any) {
    console.log(err);
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* setDefaultGroupMember(action: any) {
  try {
    let params = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator =
      yield Api.group.setDefaultGroupMemberService(params);
    yield put(
      groupSlice.actions.getGroupMembers({
        id: params.id,
        page: params.filterData.page,
        limit: params.filterData.limit,
      }),
    );
    yield put(loaderSlice.actions.hide());
    toast.success('Department head changed successfully');
  } catch (err: any) {
    console.log(err);
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* recoverGroup(action: any) {
  try {
    const { id, ...payload } = action.payload;
    yield Api.group.recoverGroup({ id, ...payload });
    toast.success('Department recovered successfully');
    yield put(
      groupSlice.actions.getGroupMembers({
        id: action.payload.id,
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      }),
    );
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
  }
}

export default function* group() {
  yield takeEvery(CREATE_GROUP, createGroup);
  yield takeEvery(GET_GROUPS_LIST, getGroupsList);
  yield takeEvery(GET_GROUP_MEMBERS, getGroupMembers);
  yield takeEvery(UPDATE_GROUP, updateGroup);
  yield takeEvery(DELETE_GROUP, deleteGroup);
  yield takeEvery(DELETE_GROUP_MEMBER, deleteGroupMember);
  yield takeEvery(ADD_GROUP_MEMBERS, addGroupMembers);
  yield takeEvery(SET_DEFAULT_GROUP_MEMBER, setDefaultGroupMember);
  yield takeEvery(RECOVER_GROUP, recoverGroup);
}

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import { takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import loaderSlice from '../slices/loader.slice';
import userSlice from '../slices/user.slice';
import Api from '../../services/Apis/index';
import { navigator } from '../../navigation/navigator';
import {
  BULK_CREATE_USER,
  CREATE_USER,
  DELETE_USER,
  GET_USERS_LIST,
  RECOVER_USER,
  SEARCH_USER,
  UPDATE_USER,
} from '../actions/user.actions';
import { ResponseGenerator } from '../../interface/Response';
import { PAGE_CONFIG } from '../../common/utility';

function* createUser(action: any) {
  try {
    const { name, email, password, departmentId } = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.user.createUserService({
      name,
      email,
      password,
      departmentId,
    });
    // navigator.history.replace("/dashboard/users");
    yield put(loaderSlice.actions.hide());
    if (response?.data?.recover) {
      yield put(userSlice.actions.recoverUserInit(response.data.user));
      return;
    }
    yield put(
      userSlice.actions.getUsersList({
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      })
    );
    toast.success('User created successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* updateUser(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    yield Api.user.updateUserService(action.payload.values);
    navigator.history.replace('/dashboard/users');
    yield put(
      userSlice.actions.getUsersList({
        page: action.payload.filterData.page,
        limit: action.payload.filterData.limit,
      })
    );
    yield put(loaderSlice.actions.hide());
    toast.success('User updated successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* getUsersList(action: any) {
  try {
    const params = action.payload;

    yield put(loaderSlice.actions.showSmall());
    const response: ResponseGenerator = yield Api.user.getUsersListService(
      params
    );
    yield put(userSlice.actions.setUsersList(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hideSmall());
  }
}

function* getSearchUserList(action: any) {
  try {
    const params = { text: action.payload };

    yield put(loaderSlice.actions.showSmall());
    const response: ResponseGenerator = yield Api.user.searchUserListService(
      params
    );
    yield put(userSlice.actions.setUsersList(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hideSmall());
  }
}

function* deleteUser(action: any) {
  try {
    const params = { userId: action.payload.selectedDeleteUser };

    yield put(loaderSlice.actions.show());
    yield Api.user.deleteUserService(params);
    // yield put(
    //   userSlice.actions.getUsersList({
    //     page: action.payload.req.page,
    //     limit: action.payload.req.limit,
    //   })
    // );
    action.payload.setFilterData({
      limit: action.payload.req.limit,
      page: action.payload.req.page,
    })
    yield put(loaderSlice.actions.hide());
    toast.success('User deleted successfully');
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* bulkCreateUser(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.user.bulkCreateUserService(
      action.payload
    );
    yield put(userSlice.actions.bulkCreateUserResult(response.data?.data));
    yield put(loaderSlice.actions.hide());
    yield put(
      userSlice.actions.getUsersList({
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      })
    );
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
    yield put(loaderSlice.actions.hide());
  }
}

function* recoverUser(action: any) {
  try {
    const { id, ...payload } = action.payload;
    yield Api.user.recoverUser({ id, ...payload });
    toast.success('User recovered successfully');
    yield put(
      userSlice.actions.getUsersList({
        page: PAGE_CONFIG.DEFAULT_PAGE_NO,
        limit: PAGE_CONFIG.LIMIT,
      })
    );
  } catch (err: any) {
    toast.error(err.response?.data?.message ?? err.message, {
      toastId: err.response?.data?.message ?? err.message
    });
  }
}

export default function* user() {
  yield takeEvery(CREATE_USER, createUser);
  yield takeEvery(GET_USERS_LIST, getUsersList);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(SEARCH_USER, getSearchUserList);
  yield takeEvery(BULK_CREATE_USER, bulkCreateUser);
  yield takeEvery(RECOVER_USER, recoverUser);
}

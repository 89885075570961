import React from "react";
import { Provider } from "react-redux";

import store from "./redux/store/store";
import Router from "./navigation/routes";
import Toast from "./components/Toast/Toast";
import { Spinner } from "./components/Loader/Loader";
import { Size, useWindowSize } from "./common/getScreenSize";

function App() {
  const size: Size = useWindowSize();
  if (size?.width > 1920) {
    document.body.style.zoom = `${(size?.width / 1920) * 100}%`;
    document.body.style.height = `${(1920 / size?.width) * 100}vh`;
    document.getElementById("root")!.style.height = `${(1920 / size?.width) * 100}vh`;
  } else {
    document.body.style.zoom = "";
    document.body.style.height = "";
    document.getElementById("root")!.style.height = "";
  }
  return (
    <div className="flex w-full flex-1">
      <Provider store={store}>
        <Spinner full />
        <Toast />
        <Router />
      </Provider>
    </div>
  );
}

export default App;

/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
import authSlice, { initialAuthState } from '../slices/auth.slice';
import loaderSlice, { initialLoaderState } from '../slices/loader.slice';
import userSlice, { initialUserState } from '../slices/user.slice';
import ticketSlice, { initialTicketState } from '../slices/ticket.slice';
import groupSlice, { initialGroupState } from '../slices/group.slice';
import commonSlice, { initialCommonState } from '../slices/common.slice';
import profileSlice, { initialUserProfileState } from '../slices/profile.slice';
import { CLEAR_AUTHENTICATION } from '../actions/auth.actions';

const initialState: RootState = {
  auth: initialAuthState,
  loader: initialLoaderState,
  user: initialUserState,
  ticket: initialTicketState,
  group: initialGroupState,
  common: initialCommonState,
  profile: initialUserProfileState,
};

const appReducer = combineReducers({
  auth: authSlice.reducer,
  loader: loaderSlice.reducer,
  user: userSlice.reducer,
  ticket: ticketSlice.reducer,
  group: groupSlice.reducer,
  common: commonSlice.reducer,
  profile: profileSlice.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: any) => {
  if (action.type === CLEAR_AUTHENTICATION) {
    return appReducer(initialState, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

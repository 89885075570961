/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const setTicketCount = (state: any, action: { payload: any }) => ({
  ...state,
  totalTickets: action.payload,
});

const setGroupTickets = (state: any, action: any) => ({
  ...state,
  groupTickets: action.payload,
});

const setUserTickets = (state: any, action: { payload: any }) => ({
  ...state,
  userTickets: action.payload,
});

const setAssignedTickets = (state: any, action: { payload: any }) => ({
  ...state,
  assignedTickets: action.payload,
});

const setTicketById = (state: any, action: { payload: any }) => ({
  ...state,
  ticketById: action.payload,
});

const getTicketsForUser = (state: any, action: { payload: any }) => state;

const getTicketById = (state: any, action: { payload: any }) => state;

const getTicketsForGroup = (state: any, action: { payload: any }) => state;

const createTicket = (state: any, action: { payload: any }) => ({
  ...state,
  createTicketLoader: true,
});

const setCreateTicketLoader = (state: any) => ({
  ...state,
  createTicketLoader: false,
});

const deleteTicket = (state: any, action: { payload: any }) => state;

const updateTicketStatus = (state: any, action: { payload: any }) => state;

const updateTicket = (state: any, action: { payload: any }) => ({
  ...state,
  createTicketLoader: true,
});

const getCommentsForTicket = (state: any, action: { payload: any }) => state;

const updateComment = (state: any, action: { payload: any }) => state;

const createComment = (state: any, action: { payload: any }) => state;

const createLog = (state: any, action: { payload: any }) => state;

const deleteComment = (state: any, action: { payload: any }) => state;

const setComments = (state: any, action: { payload: any }) => ({
  ...state,
  comments: action.payload,
});

const searchGroups = (state: any, action: { payload: any }) => state;

const setGroupSearch = (state: any, action: { payload: any }) => ({
  ...state,
  groupSearch: action.payload,
});

const getAssignedTickets = (state: any, action: { payload: any }) => state;

const getGroupAssignedTickets = (state: any, action: { payload: any }) => state;

const reassignTickets = (state: any, action: { payload: any }) => state;

const reassignTicketsGroup = (state: any, action: { payload: any }) => state;

const exportData = (state: any, action: { payload: any }) => state;

const setCreateTicketModal = (state: any, action: { payload: any }) => ({
  ...state,
  createTicketModal: action.payload,
});

const removeAttachment = (state: InitialTicketState, action: { payload: { id: number; ticketId: number; filterData: any; setFilterData: any; } }) => state;
const removeAttachmentResult = (state: InitialTicketState, action: { payload: { id: number; } }) => ({
  ...state,
});

export const initialTicketState: InitialTicketState = {
  groupTickets: [],
  userTickets: [],
  totalTickets: undefined,
  comments: [],
  groupSearch: [],
  assignedTickets: [],
  createTicketLoader: false,
  createTicketModal: undefined,
  ticketById: undefined,
};

type InitialTicketState = {
  groupTickets: any[];
  userTickets: any[];
  totalTickets?: number;
  comments: any[];
  groupSearch: any[];
  assignedTickets: any[];
  createTicketLoader: boolean;
  createTicketModal?: any;
  ticketById: any;
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState: initialTicketState,
  reducers: {
    setGroupTickets,
    setUserTickets,
    setTicketCount,
    getTicketsForUser,
    getTicketsForGroup,
    createTicket,
    setCreateTicketLoader,
    deleteTicket,
    updateTicketStatus,
    updateTicket,
    getCommentsForTicket,
    updateComment,
    createComment,
    createLog,
    deleteComment,
    setComments,
    searchGroups,
    setGroupSearch,
    getAssignedTickets,
    reassignTickets,
    setAssignedTickets,
    getGroupAssignedTickets,
    reassignTicketsGroup,
    exportData,
    setCreateTicketModal,
    getTicketById,
    setTicketById,
    removeAttachment,
    removeAttachmentResult,
  },
});

export default ticketSlice;

/* eslint-disable import/no-unresolved */
import { all } from 'redux-saga/effects';
import authSaga from '../sagas/auth.saga';
import user from '../sagas/user.saga';
import ticket from '../sagas/ticket.saga';
import group from '../sagas/group.saga';
import common from '../sagas/common.saga';
import profileSaga from '../sagas/profile.saga';

export default function* rootSaga() {
  yield all([authSaga(), user(), ticket(), group(), profileSaga(), common()]);
}

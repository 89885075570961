/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialLoaderState = {
  visible: false,
  visibleFull: false,
};

export const showSmall = (state: { visible: boolean; }) => { state.visible = true; };
export const show = (state: { visibleFull: boolean; }) => { state.visibleFull = true; };

export const hideSmall = (state: { visible: boolean; }) => { state.visible = false; };
export const hide = (state: { visibleFull: boolean; }) => { state.visibleFull = false; };

const loaderSlice = createSlice({
  name: 'loader',
  initialState: initialLoaderState,
  reducers: {
    show,
    hide,
    showSmall,
    hideSmall,
  },
});

export default loaderSlice;

import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const Spinner = ({ full }) => {
  const visible = useSelector((state) => state.loader[full ? 'visibleFull' : 'visible']);
  return (
    <>
      {visible
        ? (
          <div className="absolute h-full w-full flex items-center justify-center z-50 bg-white bg-opacity-75">
            <ClipLoader
              css={`
              display: block;
              argin: 0 auto;
              border-color: '#0E278F';
            `}
              size={55}
              color="#0E278F"
              loading
            />
          </div>
        )
        : null}
    </>
  );
};
Spinner.propTypes = {
  full: PropTypes.bool,
};

Spinner.defaultProps = {
  full: false,
};

export default Spinner;

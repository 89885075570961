const Trash: React.FC<{ size?: number; pathFill?: string }> = ({
  size = 14,
  pathFill = "#fff",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size}`}
      fill="none"
      viewBox="0 0 14 14">
      <path
        fill={`${pathFill}`}
        d="M12.334 3.667V13a.667.667 0 01-.667.667H2.333A.667.667 0 011.667 13V3.667H.333V2.334h13.334v1.333h-1.333zM3 3.667v8.666h8V3.668H3zM3.667.333h6.667v1.334H3.667V.333zm2.667 5.334h1.333v4.667H6.334V5.667z"></path>
    </svg>
  );
};

export default Trash;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from "../../../config/config";
import { ResponseGenerator } from "../../../interface/Response";
import { request } from "../../request/Request";
// import axios from 'axios';

const getTicketsForUserEndpoint = "v1/ticket/get-ticket-for-user";
const getTicketsForGroupEndpoint = "v1/ticket/get-ticket-for-group";
const getTicketByIdEndpoint = "v1/ticket/get-ticket-by-id";
const createTicketEndpoint = "v1/ticket/create";
const deleteTicketEndpoint = "v1/ticket/delete-ticket";
const updateTicketStatusEndpoint = "v1/ticket/change-status";
const updateTicketEndpoint = "v1/ticket/update-ticket";
const searchGroupsEndpoint = "v1/group";
const getAssignedTicketsEndpoint = "v1/ticket/get-assigned-tickets";
const getGroupAssignedTicketsEndpoint = "v1/ticket/get-assigned-tickets-for-group";
const reassignTicketsEndpoint = "v1/ticket/reassign-ticket";
const reassignTicketsGroupEndpoint = "v1/ticket/reassign-ticket-group";
const exportDataEndpoint = "v1/ticket/exportData";
const removeAttachmentEndpoint = "v1/ticket/remove-attachments";

const commentEndpoint = "v1/comment";

function* getTicketsForUser(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}${getTicketsForUserEndpoint}`,
    { params },
  );
  return response;
}

function* getTicketsForGroup(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}${getTicketsForGroupEndpoint}`,
    { params },
  );
  return response;
}

function* getTicketById(ticketId: number) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}${getTicketByIdEndpoint}/${ticketId}`,
  );
  return response;
}

function* createTicket(ticket: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}${createTicketEndpoint}`,
    ticket,
  );
  return response;
}

function* deleteTicket(params: any) {
  const response: ResponseGenerator = yield request.delete(
    `${APP_ENTRYPOINT}${deleteTicketEndpoint}`,
    { params },
  );
  return response;
}

function* updateTicketStatus(ticketDetails: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}${updateTicketStatusEndpoint}`,
    ticketDetails,
  );
  return response;
}

function* updateTicket(ticketDetails: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}${updateTicketEndpoint}`,
    ticketDetails,
  );
  return response;
}

function* createComment(comment: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}${commentEndpoint}`,
    comment,
  );
  return response;
}

function* updateComment(comment: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}${commentEndpoint}`,
    comment,
  );
  return response;
}

function* getCommentsForTicket(params: any) {
  const response: ResponseGenerator = yield request.get(`${APP_ENTRYPOINT}${commentEndpoint}`, {
    params,
  });
  return response;
}

function* deleteComment(params: any) {
  const response: ResponseGenerator = yield request.get(`${APP_ENTRYPOINT}${commentEndpoint}`, {
    params,
  });
  return response;
}

function searchGroups(params: any) {
  return request.get(`${APP_ENTRYPOINT}${searchGroupsEndpoint}`, { params });
}

function* getAssignedTickets(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}${getAssignedTicketsEndpoint}`,
    { params },
  );
  return response;
}

function* getGroupAssignedTickets(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}${getGroupAssignedTicketsEndpoint}`,
    { params },
  );
  return response;
}

function* reassignTickets(ticket: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}${reassignTicketsEndpoint}`,
    ticket,
  );
  return response;
}

function* reassignTicketsGroup(ticket: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}${reassignTicketsGroupEndpoint}`,
    ticket,
  );
  return response;
}

function* exportData(params: any) {
  const response: ResponseGenerator = yield request.get(`${APP_ENTRYPOINT}${exportDataEndpoint}`, {
    params,
    responseType: "blob",
  });
  return response;
}

function* removeAttachment(attachments: number[], ticketId: number) {
  const response: ResponseGenerator = yield request.delete(
    `${APP_ENTRYPOINT}${removeAttachmentEndpoint}`,
    { params: { attachments, ticketId } },
  );
  return response;
}

const ticket = {
  getTicketsForUser,
  getTicketsForGroup,
  createTicket,
  deleteTicket,
  updateTicketStatus,
  updateTicket,
  createComment,
  updateComment,
  getCommentsForTicket,
  deleteComment,
  searchGroups,
  getAssignedTickets,
  reassignTickets,
  getGroupAssignedTickets,
  reassignTicketsGroup,
  exportData,
  getTicketById,
  removeAttachment,
};

export default ticket;

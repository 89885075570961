import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import loaderSlice from '../../redux/slices/loader.slice';

export const SuspenseLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderSlice.actions.show());
  }, []);

  useEffect(() => (() => {
    dispatch(loaderSlice.actions.hide());
  }), []);

  return (<div className="w-full h-full"> </div>);
};

export default SuspenseLoader;

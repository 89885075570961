import { takeEvery, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { PayloadAction } from "@reduxjs/toolkit";
import loaderSlice from "../slices/loader.slice";
import {
  CREATE_TICKET,
  GET_TICKETS_FOR_USER,
  GET_TICKETS_FOR_GROUP,
  UPDATE_TICKET,
  DELETE_TICKET,
  GET_COMMENTS,
  UPDATE_COMMENT,
  CREATE_COMMENT,
  DELETE_COMMENT,
  SEARCH_GROUPS,
  UPDATE_TICKET_STATUS,
  GET_ASSIGNED_TICKETS,
  REASSIGN_TICKETS,
  GET_GROUP_ASSIGNED_TICKETS,
  REASSIGN_TICKETS_GROUP,
  EXPORT_DATA,
  GET_TICKET_BY_ID,
  REMOVE_ATTACHMENT,
} from "../actions/ticket.actions";
import ticketSlice from "../slices/ticket.slice";
import Api from "../../services/Apis/index";
import { ResponseGenerator } from "../../interface/Response";

function* createTicket(action: any) {
  const {
    title,
    description,
    groupId,
    createdBy,
    setSubmitted,
    userId,
    setFilterData,
    filterData,
    priority,
    high_priority_reason,
    attachments,
  } = action.payload;
  try {
    // yield put(loaderSlice.actions.show());
    if (priority === "high" || priority === "critical") {
      yield Api.ticket.createTicket({
        title,
        description,
        groupId,
        createdBy,
        userId,
        priority,
        high_priority_reason,
        attachments,
      });
    } else {
      yield Api.ticket.createTicket({
        title,
        description,
        groupId,
        createdBy,
        userId,
        priority,
        attachments,
      });
    }

    yield put(ticketSlice.actions.setCreateTicketLoader());
    if (setFilterData && filterData) {
      yield setFilterData({ ...filterData });
    }
    // yield put(loaderSlice.actions.hide());
    yield setSubmitted(false);
    yield put(ticketSlice.actions.setCreateTicketModal(undefined));
    toast.success("Ticket created successfully");
  } catch (error: any) {
    yield put(ticketSlice.actions.setCreateTicketLoader());
    yield put(loaderSlice.actions.hide());
    setSubmitted(false);
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getTicketsForUser(action: any) {
  try {
    yield put(loaderSlice.actions.showSmall());
    const params = action.payload;
    const response: ResponseGenerator = yield Api.ticket.getTicketsForUser(params);
    yield put(ticketSlice.actions.setUserTickets(response.data.data));
    yield put(ticketSlice.actions.setTicketCount(response.data.data.totalDocs));
    yield put(loaderSlice.actions.hideSmall());
  } catch (error: any) {
    yield put(loaderSlice.actions.hideSmall());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getTicketsForGroup(action: any) {
  try {
    yield put(loaderSlice.actions.showSmall());
    const params = action.payload;
    console.log(action.payload);
    const response: ResponseGenerator = yield Api.ticket.getTicketsForGroup(params);
    yield put(ticketSlice.actions.setGroupTickets(response.data.data));
    yield put(ticketSlice.actions.setTicketCount(response.data.data.totalDocs));
    yield put(loaderSlice.actions.hideSmall());
  } catch (error: any) {
    yield put(loaderSlice.actions.hideSmall());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* updateTicket(action: any) {
  const { filterData, setFilterData, setSubmitted, ...reqPayload } = action.payload;
  try {
    yield put(loaderSlice.actions.show());
    yield Api.ticket.updateTicket(reqPayload);
    yield put(loaderSlice.actions.hide());
    toast.success("Ticket updated successfully");
    yield put(ticketSlice.actions.setCreateTicketLoader());
    yield put(ticketSlice.actions.setCreateTicketModal(undefined));
    yield setFilterData({ ...filterData });
    setSubmitted(false);
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    setSubmitted(false);
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* deleteTicket(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const param = action.payload;
    const { setFilterData, filterData, page, docsCount, createdBy, searchText } = action.payload;
    yield Api.ticket.deleteTicket({ id: param.id });
    let req = { ...filterData, userId: createdBy };
    docsCount === 1 && Number(page) > 1
      ? (req = {
          ...filterData,
          page: Number(page) - 1,
          userId: createdBy,
        })
      : (req = { ...filterData, userId: createdBy });
    // yield put(
    //   ticketSlice.actions.getTicketsForUser(req)
    // );
    yield setFilterData({
      text: searchText,
      limit: req.limit,
      page: req.page,
    });
    yield put(loaderSlice.actions.hide());
    toast.success("Ticket deleted successfully");
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getCommentsForTicket(action: any) {
  try {
    const param = action.payload;
    const response: ResponseGenerator = yield Api.ticket.getCommentsForTicket(param);
    yield put(ticketSlice.actions.setComments(response.data.data));
  } catch (error: any) {
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* updateComment(action: any) {
  try {
    yield Api.ticket.updateComment(action.payload);
    yield put(
      ticketSlice.actions.getCommentsForTicket({
        page: 1,
        limit: 5,
        ticketId: action.payload.id,
      }),
    );
    toast.success("Comment updated successfully");
  } catch (error: any) {
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* createComment(action: any) {
  try {
    yield Api.ticket.createComment(action.payload);
    yield put(
      ticketSlice.actions.getCommentsForTicket({
        page: 1,
        limit: 5,
        ticketId: action.payload.ticketId,
      }),
    );
    // toast.success('Comment created successfully');
  } catch (error: any) {
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* deleteComment(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const param = action.payload;
    yield Api.ticket.deleteComment({ id: param.id });
    yield put(
      ticketSlice.actions.getTicketsForUser({
        page: 1,
        limit: 5,
        userId: param.createdBy,
      }),
    );
    yield put(loaderSlice.actions.hide());
    toast.success("Ticket deleted successfully");
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* searchGroups(action: any) {
  try {
    const param = action.payload;
    const response: ResponseGenerator = yield Api.ticket.searchGroups(param);
    yield put(ticketSlice.actions.setGroupSearch(response.data?.data?.docs ?? []));
    // toast.success('Ticket deleted successfully');
  } catch (error: any) {
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* updateTicketStatus(action: any) {
  try {
    const { newStatus, id, filterData, page, currentUserId, reason } = action.payload;
    yield Api.ticket.updateTicketStatus({ newStatus, id, reason });
    yield Api.ticket.createComment({
      ticketId: id,
      comment: reason,
      isReassignComment: true,
    });
    // if (page == 'assigned-tickets') {
    //   yield put(ticketSlice.actions.getAssignedTickets({ ...filterData, userId: currentUserId }));
    // }
    if (page === "group-tickets") {
      yield put(ticketSlice.actions.getTicketsForGroup(filterData));
    }
    if (page === "user-tickets") {
      yield put(
        ticketSlice.actions.getTicketsForUser({
          ...filterData,
          userId: currentUserId,
        }),
      );
    }
    if (page === "assigned-tickets") {
      yield put(
        ticketSlice.actions.getAssignedTickets({
          ...filterData,
          // userId: currentUserId,
        }),
      );
    }
    toast.success("Status updated successfully");
  } catch (error: any) {
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getAssignedTickets(action: any) {
  try {
    yield put(loaderSlice.actions.showSmall());
    const response: ResponseGenerator = yield Api.ticket.getAssignedTickets(action.payload);
    yield put(ticketSlice.actions.setAssignedTickets(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (error: any) {
    yield put(loaderSlice.actions.hideSmall());
    console.log("assigned");
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getTicketById(action: any) {
  try {
    yield put(loaderSlice.actions.showSmall());
    const response: ResponseGenerator = yield Api.ticket.getTicketById(action.payload);
    yield put(ticketSlice.actions.setTicketById(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (error: any) {
    yield put(loaderSlice.actions.hideSmall());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* getGroupAssignedTickets(action: any) {
  try {
    yield put(loaderSlice.actions.showSmall());
    const response: ResponseGenerator = yield Api.ticket.getGroupAssignedTickets(action.payload);
    yield put(ticketSlice.actions.setAssignedTickets(response.data.data));
    yield put(loaderSlice.actions.hideSmall());
  } catch (error: any) {
    yield put(loaderSlice.actions.hideSmall());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* reassignTickets(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const {
      filterData,
      page,
      currentUserId,
      ticketId,
      reassignReason: comment,
      ...rest
    } = action.payload;
    yield Api.ticket.reassignTickets({
      ...rest,
      ticketId,
      reassignReason: comment,
    });
    yield Api.ticket.createComment({
      ticketId,
      comment,
      isReassignComment: true,
    });
    if (page === "group-tickets") {
      yield put(
        ticketSlice.actions.getTicketsForGroup({
          page: filterData.page,
          limit: filterData.limit,
          groupId: filterData.groupId,
          status: filterData?.status,
          text: filterData?.text,
          createdDateTo: filterData?.createdDateTo,
          createdDateFrom: filterData?.createdDateFrom,
          priority: filterData?.priority,
        }),
      );
    }
    if (page === "assigned-tickets") {
      yield put(
        ticketSlice.actions.getAssignedTickets({
          page: filterData.page,
          limit: filterData.limit,
          userId: filterData.userId ? filterData?.userId : undefined,
          groupId: filterData.groupId,
          status: filterData?.status,
          text: filterData?.text,
          createdDateTo: filterData?.createdDateTo,
          createdDateFrom: filterData?.createdDateFrom,
          priority: filterData?.priority,
        }),
      );
    }
    yield put(loaderSlice.actions.hide());
    toast.success("Ticket reassigned successfully");
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* reassignTicketsGroup(action: any) {
  try {
    yield put(loaderSlice.actions.show());
    const {
      filterData,
      page,
      currentUserId,
      ticketId,
      reassignReason: comment,
      ...rest
    } = action.payload;
    yield Api.ticket.reassignTicketsGroup({
      ...rest,
      ticketId,
      reassignReason: comment,
    });
    yield Api.ticket.createComment({
      ticketId,
      comment,
      isReassignComment: true,
    });
    if (page === "group-tickets") {
      yield put(
        ticketSlice.actions.getTicketsForGroup({
          page: filterData.page,
          limit: filterData.limit,
          groupId: filterData.groupId,
          status: filterData?.status,
          text: filterData?.text,
          createdDateTo: filterData?.createdDateTo,
          createdDateFrom: filterData?.createdDateFrom,
          priority: filterData?.priority,
        }),
      );
    }
    if (page === "assigned-tickets") {
      yield put(
        ticketSlice.actions.getAssignedTickets({
          page: filterData.page,
          limit: filterData.limit,
          userId: filterData.userId ? filterData?.userId : undefined,
          groupId: filterData.groupId,
          status: filterData?.status,
          text: filterData?.text,
          createdDateTo: filterData?.createdDateTo,
          createdDateFrom: filterData?.createdDateFrom,
          priority: filterData?.priority,
        }),
      );
    }
    yield put(loaderSlice.actions.hide());
    toast.success("Ticket reassigned successfully");
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

function* exportData(action: any) {
  try {
    const { columns, filterData } = action.payload;
    yield put(loaderSlice.actions.show());
    const response: ResponseGenerator = yield Api.ticket.exportData({
      columns,
      ...filterData,
    });
    const [, filename] = response.headers["content-disposition"].split("filename=");
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    yield put(loaderSlice.actions.hide());
  } catch (error: any) {
    console.log(error.response);
    yield put(loaderSlice.actions.hide());
    const errorMsg: string = yield JSON.parse(yield error?.response?.data?.text() ?? "")?.message ??
      error?.message;
    toast.error(errorMsg, {
      toastId: errorMsg,
    });
  }
}

function* removeAttachments(
  action: PayloadAction<{ id: number; ticketId: number; filterData: any; setFilterData: any; }>
) {
  try {
    yield put(loaderSlice.actions.show());
    const { id, ticketId, filterData, setFilterData } = action.payload;
    yield Api.ticket.removeAttachment([id], ticketId);
    // yield put(ticketSlice.actions.removeAttachmentResult({ id }));
    if (filterData && setFilterData) {
      yield setFilterData({ ...filterData });
    }
    yield put(loaderSlice.actions.hide());
  } catch (error: any) {
    yield put(loaderSlice.actions.hide());
    toast.error(error.response?.data?.message ?? error.message, {
      toastId: error.response?.data?.message ?? error.message,
    });
  }
}

export default function* ticket() {
  yield takeEvery(CREATE_TICKET, createTicket);
  yield takeEvery(GET_TICKETS_FOR_USER, getTicketsForUser);
  yield takeEvery(GET_TICKETS_FOR_GROUP, getTicketsForGroup);
  yield takeEvery(UPDATE_TICKET, updateTicket);
  yield takeEvery(DELETE_TICKET, deleteTicket);
  yield takeEvery(GET_COMMENTS, getCommentsForTicket);
  yield takeEvery(UPDATE_COMMENT, updateComment);
  yield takeEvery(CREATE_COMMENT, createComment);
  yield takeEvery(DELETE_COMMENT, deleteComment);
  yield takeEvery(SEARCH_GROUPS, searchGroups);
  yield takeEvery(UPDATE_TICKET_STATUS, updateTicketStatus);
  yield takeEvery(GET_ASSIGNED_TICKETS, getAssignedTickets);
  yield takeEvery(GET_GROUP_ASSIGNED_TICKETS, getGroupAssignedTickets);
  yield takeEvery(REASSIGN_TICKETS, reassignTickets);
  yield takeEvery(REASSIGN_TICKETS_GROUP, reassignTicketsGroup);
  yield takeEvery(EXPORT_DATA, exportData);
  yield takeEvery(GET_TICKET_BY_ID, getTicketById);
  yield takeEvery(REMOVE_ATTACHMENT, removeAttachments);
}

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { APP_ENTRYPOINT } from '../../../config/config';
import { ResponseGenerator } from '../../../interface/Response';
import { request } from '../../request/Request';
// import axios from 'axios';

function* getUsersListService(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}v1/users/getUsers`,
    { params },
  );
  return response;
}

function* deleteUserService(params: any) {
  const response: ResponseGenerator = yield request.delete(
    `${APP_ENTRYPOINT}v1/users/deleteUser`,
    { params },
  );
  return response;
}

function* createUserService(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/users/createUser`,
    params,
  );
  return response;
}

function* bulkCreateUserService(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/users/bulkCreateUser`,
    params,
  );
  return response;
}

function* updateUserService(values: any) {
  const response: ResponseGenerator = yield request.put(
    `${APP_ENTRYPOINT}v1/users/updateUser`,
    values,
  );
  return response;
}

function* searchUserListService(params: any) {
  const response: ResponseGenerator = yield request.get(
    `${APP_ENTRYPOINT}v1/users/search-user`,
    { params },
  );
  return response;
}

function* recoverUser(params: any) {
  const response: ResponseGenerator = yield request.post(
    `${APP_ENTRYPOINT}v1/users/recoverUser`,
    params,
  );
  return response;
}

const users = {
  getUsersListService,
  deleteUserService,
  createUserService,
  updateUserService,
  searchUserListService,
  bulkCreateUserService,
  recoverUser,
};

export default users;

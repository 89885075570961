/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserState = {
  // attributes: {
  //   email: string;
  //   sub: string;
  // };
  email: string;
  id: string;
  name: string;
  role_id: string;
  last_login_on: Date | null;
  groups: {
    id: number;
    roles: {
      role: string;
      roleDetails: {
        display_name: string;
        name: string;
      };
    }[];
    name: string;
  }[];
};

export type InitialState = {
  authenticated: boolean;
  initialAuthentcationRequest: boolean;
  user?: UserState;
}

export const initialAuthState: InitialState = {
  user: undefined,
  authenticated: false,
  initialAuthentcationRequest: false,
};

export const checkauth = (state: InitialState) => state;

// eslint-disable-next-line no-unused-vars
export const login = (
  state: InitialState,
  action: PayloadAction<{ password: string; username: string }>,
) => state;

export const logout = (state: InitialState) => state;

export const setAuthenticatedUser = (
  state: InitialState,
  action: PayloadAction<any>,
) => {
  state.user = action.payload;
  state.authenticated = true;
  state.initialAuthentcationRequest = true;
};

export const setAuthentication = (
  state: InitialState,
  action: PayloadAction<boolean>,
) => {
  state.authenticated = action.payload;
  state.initialAuthentcationRequest = true;
};
// eslint-disable-next-line no-unused-vars
export const clearAuthentication = (
  state: InitialState,
  action: PayloadAction<void>,
) => {
  state.authenticated = false;
  state.initialAuthentcationRequest = true;
  state.user = undefined;
};

const signupBuilder = (state: any, action: any) => state;

export const forgotPassword = (state: any, action: any) => state;

export const resetpassword = (state: any, action: any) => state;

export const setpassword = (state: any, action: any) => state;

export const verifyEmail = (state: any, action: any) => state;

export const resendVerifyMail = (state: any, action: any) => state;

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login,
    logout,
    setAuthenticatedUser,
    checkauth,
    setAuthentication,
    clearAuthentication,
    signupBuilder,
    forgotPassword,
    resetpassword,
    verifyEmail,
    resendVerifyMail,
    setpassword,
  },
});
export default authSlice;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { SuspenseLoader } from '../components/SuspenseLoader/SuspenseLoader';
// import { checkIsAuthenticating } from '../../store/authentication/selector';

const PrivateRoute = ({ component: Component, path, ...rest }: any) => {
  // check whether the props contain redirect(ie 'to' exist or not)
  // eslint-disable-next-line max-len
  const authenticated = useSelector((state: { auth: { authenticated: boolean } }) => state.auth.authenticated);
  const firstLogin = useSelector(
    (state: { auth: { user: { last_login_on: Date | null } } }) => !state.auth.user?.last_login_on,
  );
  // eslint-disable-next-line max-len
  const checkIfRedirectPresent = (props: any, restParam: any) => (restParam.to ? { ...props, to: restParam.to } : props);

  // check if is authenticationg
  // eslint-disable-next-line max-len
  const isAuthenticating = useSelector((state: { auth: { initialAuthentcationRequest: boolean } }) => !state.auth.initialAuthentcationRequest);
  // checking token present in local storage
  return (
    <Route
      {...rest}
      {...path}
      render={(props) => (
        isAuthenticating
          ? (
            <SuspenseLoader />
          )
          : authenticated
            ? firstLogin
              ? path !== '/setPassword'
                ? (<Redirect to={{ pathname: '/setPassword' }} />)
                : (<Component {...checkIfRedirectPresent(props, rest)} />)
              : path !== '/setPassword'
                ? (<Component {...checkIfRedirectPresent(props, rest)} />)
                : (<Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />)
            : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
      )}
    />
  );
};

export default PrivateRoute;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  // Route,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import { navigator, setTopLevelNavigation } from "./navigator";
import { RootState } from "../redux/store/rootReducer";
import authSlice from "../redux/slices/auth.slice";
import { SuspenseLoader } from "../components/SuspenseLoader/SuspenseLoader";
import ErrorFallback from "../pages/ErrorFallback/ErrorFallback";
import Confirmation from "../components/Confirmation/Confirmation";
import Profile from "../pages/Profile/Profile";
// import Login from '../pages/Auth/Login/Login';
// import { RootState } from '../redux/store/rootReducer';
// import Register from '../pages/Auth/Register/Register';
// import VerifyEmail from '../pages/Auth/VerifyEmail/VerifyEmail';
// import Resetpassword from '../pages/Auth/Resetpassword/Resetpassword';
// import ForgotPassword from '../pages/Auth/Forgotpassword/Forgotpassword';
// import VerifyEmailSuccess from '../pages/Auth/VerifyEmailSuccess/VerifyEmailSuccess';
// import RestPasswordSuccess from '../pages/Auth/RestPasswordSuccess/RestPasswordSuccess';

const AuthLayout = lazy(() => import("../pages/Layout/AuthLayout"));
const Layout = lazy(() => import("../pages/Layout/Layout"));

export default function Routes() {
  const dispatch = useDispatch();
  const { initialAuthentcationRequest } = useSelector(
    (state: RootState) => state.auth,
  );
  useEffect(() => {
    if (!initialAuthentcationRequest) {
      dispatch(authSlice.actions.checkauth());
    }
  }, []);

  return (
    <Router ref={setTopLevelNavigation}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<SuspenseLoader />}>
          <Switch>
            <PublicRoute path="/login" component={AuthLayout} exact />
            <PublicRoute path="/register" component={AuthLayout} exact />
            <PublicRoute path="/verifyEmail" component={AuthLayout} exact />
            <PublicRoute
              path="/verifyEmailSuccess"
              component={AuthLayout}
              exact
            />
            <PublicRoute path="/forgotPassword" component={AuthLayout} exact />
            <PublicRoute path="/resetpassword" component={AuthLayout} exact />
            <PublicRoute
              path="/resetPasswordSuccess"
              component={AuthLayout}
              exact
            />

            <PrivateRoute path="/dashboard" component={Layout} />
            
            <PrivateRoute path="/setPassword" component={AuthLayout} exact />
            <Route
              path="/update-available"
              render={(props) => (
                <Confirmation
                  open
                  title="Upgrade Available"
                  message="Please click 'Upgrade Now' button to get the latest version of the app."
                  options={[{ value: true, label: 'Upgrade Now' }]}
                  close={() => { navigator.history.replace('/'); window.location.reload(); }}
                  showClose={false}
                />
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <Redirect
                  to={{ pathname: "/login" }}
                />
              )}
            />
          </Switch>
        </Suspense>
      </ErrorBoundary>
      {/* {
        initialAuthentcationRequest === true && authenticated === false
          ? (
            <>
              <Route path="/login" render={() => <Login />} />
              <Route exact path="/register" render={() => <Register />} />
              <Route exact path="/verifyEmail" render={() => <VerifyEmail />} />
              <Route exact path="/verifyEmailSuccess" render={() => <VerifyEmailSuccess />} />
              <Route exact path="/forgotPassword" render={() => <ForgotPassword />} />
              <Route exact path="/resetpassword" render={() => <Resetpassword />} />
              <Route exact path="/restPasswordSuccess" render={() => <RestPasswordSuccess />} />
            </>
          )
          : null
      } */}
      {/* {
        initialAuthentcationRequest === true && authenticated === true
          ? <Route path="/dashboard" render={() => <Layout />} />
          : null
      } */}
      {/* <Route exact path="/" render={() => <Login />} /> */}
    </Router>
  );
}

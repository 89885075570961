/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
// import { storageEngine } from '../common/helper';

const PublicRoute = ({ component: Component, ...rest }: any) => {
  // check whether the props contain redirect(ie 'to' exist or not)
  // eslint-disable-next-line max-len
  const location = useLocation();
  const checkIfRedirectPresent = (props: any, restParam: any) => (restParam.to ? { ...props, to: restParam.to } : props);

  // checking token present in local storage
  // eslint-disable-next-line max-len
  const authenticated = useSelector((state: { auth: { authenticated: boolean } }) => state.auth.authenticated);
  return (
    <Route
      {...rest}
      render={(props) => (!authenticated ? (
        <Component {...checkIfRedirectPresent(props, rest)} />
      ) : (
        <Redirect to={(location.state as { from?: Location })?.from ?? { pathname: '/dashboard' }} />
      ))}
    />
  );
};

export default PublicRoute;

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { toast } from 'react-toastify';
import { takeEvery, call, put } from 'redux-saga/effects';
import {
  SET_CURRENT_PAGE,
  SET_PLACEHOLDER_TEXT,
  SET_SEARCH_TEXT,
} from '../actions/common.actions';
import commonSlice from '../slices/common.slice';

// function* SetSearchText(action: any) {
//   try {
//     yield put(commonSlice.actions.setSearchText(action.payload));
//   } catch (err) {
//     console.error();
//   }
// }

// function* SetPlaceholderText(action: any) {
//   try {
//     yield put(commonSlice.actions.setPlaceholderText(action.payload));
//   } catch (err) {
//     console.error();
//   }
// }

// function* SetCurrentPage(action: any) {
//   try {
//     yield put(commonSlice.actions.setCurrentPage(action.payload));
//   } catch (err) {
//     console.error();
//   }
// }

function* commonSaga() {
  // yield takeEvery(SET_SEARCH_TEXT, SetSearchText);
  // yield takeEvery(SET_PLACEHOLDER_TEXT, SetPlaceholderText);
  // yield takeEvery(SET_CURRENT_PAGE, SetCurrentPage);
}

export default commonSaga;
